<template>
  <CommonLoading v-if="loading" />
  <div v-else>
    <form
      class="w-64 ml-auto mr-auto grid gap-4"
      @submit.prevent="submit"
      id="form_trial_signup_create_user"
    >
      <p class="font-sans font-medium text-center text-sm">
        {{ $t('trialSignup.stepXOfY', { x: 1, y: 2 }) }}
      </p>

      <FormAlert v-if="alert" :type="alert.type">
        <div class="flex flex-col gap-y-4">
          <div v-if="!showRiksdagenLink">{{ alert.message }}</div>
          <div v-if="showLogin">
            <span v-if="false"
              >{{
                $t('user.errors.UseExistingAccount', {
                  oppositeSiteName: false
                    ? 'Altinget'
                    : 'Mandag Morgen',
                })
              }}
            </span>
            <ul>
              <li>
                <span
                  role="button"
                  class="underline cursor-pointer text-blue"
                  @click.prevent.stop="$emit('click-login')"
                  >{{ $t('logInToContinue') }}</span
                >
                {{ $t('or') }}
              </li>
              <li>
                <NuxtLink
                  external
                  :to="{
                    name: 'userforgotpassword',
                  }"
                  class="underline text-blue"
                  >{{ $t('resetYourPasswordHere') }}</NuxtLink
                >
              </li>
            </ul>
          </div>

          <div v-if="showRiksdagenLink">
            Som medarbetare på Sveriges riksdag kan du ta del av allt digitalt
            innehåll från Altinget. Skapa ett konto
            <NuxtLink
              external
              :to="{
                name: 'usercreate',
                query: { email: '9IlxiVgs0mzR9WMmQGjB7w%3D%3D' },
              }"
              class="underline text-blue"
              >HÄR</NuxtLink
            >
            med din mejladress för att komma vidare.
          </div>
        </div>
      </FormAlert>

      <FormAlert v-if="isSSOEmail" type="info">
        <div class="flex flex-col gap-y-4">
          <span
            class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
          >
            {{ $t('sso.yourEmailIsOnAnSSOClient') }}</span
          >
          <ul>
            <li>
              <NuxtLink external :to="ssoUrl" class="underline text-blue">{{
                $t('sso.loginHereInstead')
              }}</NuxtLink>
            </li>
          </ul>
        </div>
      </FormAlert>
      <FormInput
        :modelValue="email"
        type="email"
        required
        placeholder="enterEmail"
        @blur="checkSSO"
        @update:modelValue="$emit('update:email', $event)"
      />

      <FormPassword
        :modelValue="password"
        placeholder="choosePassword"
        :title="$t('passwordRequirements')"
        :allow-toggle="true"
        @update:modelValue="$emit('update:password', $event)"
      />

      <input
        type="submit"
        class="w-full cursor-pointer py-3 px-4 text-white article-label !font-medium"
        :value="$t('Next')"
        :class="
          false
            ? 'rounded-md bg-black transform translate-y-px'
            : 'rounded-full bg-blue hover:bg-denimblue'
        "
        @click="dataLayer.trial.createUser()"
      />
    </form>
    <slot />
  </div>
</template>
<script setup lang="ts">
/**
 * There is a special case in this component where we disallow riksdagen.se
 * emails starting ATS (since they should allready have access to all papers). We
 * have no general flow for this, so a hacky solution was implemented.
 */
import type { FetchError } from 'ofetch'
import type { AlertBundle } from '~/typesManual/alert'
import type { SSOProvider } from '~/typesManual/sso'

const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()
const dataLayer = useDataLayer()
const { sentry } = useSentry()

const userStore = useUserStore()

const props = defineProps<{
  email: string
  password: string
}>()

defineEmits<{
  (event: 'update:email', value: string): void
  (event: 'update:password', value: string): void
  (event: 'click-login'): void
}>()

const loading = ref(false)
const alert = ref<AlertBundle | undefined>(undefined)
const showLogin = ref(false)
const showRiksdagenLink = ref(false)

async function submit() {
  alert.value = undefined
  showLogin.value = false
  showRiksdagenLink.value = false

  // SPECIAL CASE: Disallow riksdagen.se emails
  if (props.email.includes('@riksdagen.se')) {
    alert.value = {
      type: ALERT_TYPE.ERROR,
      message: nuxtApp.$t(
        'Som medarbetare pà Sveriges riksdag kan du ta del av allt digitalt innehäll frän Altinget. Skapa ett konto via usercreate'
      ),
    }
    showRiksdagenLink.value = true
    return
  }

  loading.value = true
  const { err, data: user } = await nuxtApp.$api.user.createUser({
    workEmail: props.email,
    password: props.password,
  })

  if (err) {
    const error = err.cause as FetchError
    if (error.response?.status === 409) {
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('user.errors.UserAlreadyExists'),
      }

      showLogin.value = true
      loading.value = false
      return
    } else if (error.response?.status === 500) {
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('ServerError'),
      }
      sentry?.captureException(
        new Error('Internal Server Error: ', {
          cause: error.response._data,
        })
      )

      loading.value = false
      return
    }

    alert.value = {
      type: ALERT_TYPE.ERROR,
      message: nuxtApp.$t('DefaultError'),
    }

    loading.value = false
    return
  }

  if (!user) {
    alert.value = {
      type: ALERT_TYPE.ERROR,
      message: nuxtApp.$t('DefaultError'),
    }
    loading.value = false
    return
  }

  const result = await userStore.loginUserJwt(user.workEmail, props.password)
  if (result.status === 'error') {
    if (result.code === 401) {
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('Wronglogin'),
      }
    } else if (result.code === 500) {
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('ServerError'),
      }
      sentry?.captureException(
        new Error('Internal Server Error: ', {
          cause: result.error,
        })
      )
    } else {
      alert.value = {
        type: ALERT_TYPE.ERROR,
        message: nuxtApp.$t('Wronglogin'),
      }
      sentry?.captureException(
        new Error('Unknown Error: ', {
          cause: result.error,
        })
      )
    }
  }

  loading.value = false
}

// Single Sign-On checks
const ssoProvider = ref<SSOProvider | null>(null)
const isSSOEmail = computed(() => Boolean(ssoProvider.value))

const ssoUrl = computed(() => {
  if (import.meta.server || !ssoProvider.value) return ''

  const isIdentity = ssoProvider.value.providerName === 'IdentityEntraId'

  const url = new URL(
    isIdentity
      ? `${window.location.origin}/api/auth/sign-in-sso`
      : ssoProvider.value.providerUrl
  )
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})

async function checkSSO() {
  if (props.email && config.public.site.allowSingleSignOn) {
    ssoProvider.value = await nuxtApp.$api.user.getSsoProviderByEmail(
      props.email
    )
  } else {
    ssoProvider.value = null
  }
}
</script>
